import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useMobileStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: '#eeeeee',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      // flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    header: {
      paddingTop: theme.spacing(3),
    },
    accordion: {
      marginTop: theme.spacing(2),
    },
  })
)
