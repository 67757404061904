import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from './style'
import Paper from '@material-ui/core/Paper'
import { useTranslation } from 'react-i18next'
import { isScreenBiggerThan } from '../../../utils/parse'
import { useMobileStyles } from './mobileStyle'

export default function ControlledAccordions() {
  const classes = useStyles()
  const mobileClasses = useMobileStyles()
  const { t, i18n } = useTranslation()

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return isScreenBiggerThan('md') ? (
    <Paper className={classes.paper} elevation={0}>
      <Typography className={classes.header} align={'center'} variant="h4" gutterBottom>
        FAQ's
      </Typography>

      <Accordion className={classes.accordion} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography className={classes.heading}>{t('WhatIsTechnical')}</Typography>
          {/*<Typography className={classes.secondaryHeading}>I am an accordion</Typography>*/}
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography>{t('WhatIsTechnicalExplain0')}</Typography>
            <Typography>1. {t('WhatIsTechnicalExplain1')}</Typography>
            <Typography>2. {t('WhatIsTechnicalExplain2')}</Typography>
            <Typography>3. {t('WhatIsTechnicalExplain3')}</Typography>
            <Typography>4. {t('WhatIsTechnicalExplain4')}</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography className={classes.heading}>{t('WhatIsValuation')}</Typography>
          {/*<Typography className={classes.secondaryHeading}>You are currently not an owner</Typography>*/}
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography>{t('WhatIsValuationExplain0')}</Typography>
            <Typography>1. {t('WhatIsValuationExplain1')}</Typography>
            <Typography>2. {t('WhatIsValuationExplain2')}</Typography>
            <Typography>3. {t('WhatIsValuationExplain3')}</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
          <Typography className={classes.heading}>{t('WhatIsQuality')}</Typography>
          {/*<Typography className={classes.secondaryHeading}>*/}
          {/*  Filtering has been entirely disabled for whole web server*/}
          {/*</Typography>*/}
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography>{t('WhatIsQualityExplain0')}</Typography>
            <Typography>1. {t('WhatIsQualityExplain1')}</Typography>
            <Typography>2. {t('WhatIsQualityExplain2')}</Typography>
            <Typography>3. {t('WhatIsQualityExplain3')}</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      {/*<Accordion className={classes.accordion} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>*/}
      {/*  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">*/}
      {/*    <Typography className={classes.heading}>What is Quality Score?</Typography>*/}
      {/*  </AccordionSummary>*/}
      {/*  <AccordionDetails>*/}
      {/*    <Typography>*/}
      {/*      AI Rating is a scoring system to evaluate potential and liquid stocks. In a nutshell, for each company, we*/}
      {/*      look at many different sets of metrics, divided into three specific criteria: Technical Score, Valuation*/}
      {/*      Score and Quality Score, from which we generate scores for stocks*/}
      {/*    </Typography>*/}
      {/*  </AccordionDetails>*/}
      {/*</Accordion>*/}
    </Paper>
  ) : (
    <Paper className={mobileClasses.paper} elevation={0}>
      <Typography className={mobileClasses.header} align={'center'} variant="h4" gutterBottom>
        FAQ's
      </Typography>

      <Accordion className={mobileClasses.accordion} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography className={mobileClasses.heading}>{t('WhatIsTechnical')}</Typography>
          {/*<Typography className={mobileClasses.secondaryHeading}>I am an accordion</Typography>*/}
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography>{t('WhatIsTechnicalExplain0')}</Typography>
            <Typography>1. {t('WhatIsTechnicalExplain1')}</Typography>
            <Typography>2. {t('WhatIsTechnicalExplain2')}</Typography>
            <Typography>3. {t('WhatIsTechnicalExplain3')}</Typography>
            <Typography>4. {t('WhatIsTechnicalExplain4')}</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className={mobileClasses.accordion} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography className={mobileClasses.heading}>{t('WhatIsValuation')}</Typography>
          {/*<Typography className={mobileClasses.secondaryHeading}>You are currently not an owner</Typography>*/}
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography>{t('WhatIsValuationExplain0')}</Typography>
            <Typography>1. {t('WhatIsValuationExplain1')}</Typography>
            <Typography>2. {t('WhatIsValuationExplain2')}</Typography>
            <Typography>3. {t('WhatIsValuationExplain3')}</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className={mobileClasses.accordion} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
          <Typography className={mobileClasses.heading}>{t('WhatIsQuality')}</Typography>
          {/*<Typography className={mobileClasses.secondaryHeading}>*/}
          {/*  Filtering has been entirely disabled for whole web server*/}
          {/*</Typography>*/}
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography>{t('WhatIsQualityExplain0')}</Typography>
            <Typography>1. {t('WhatIsQualityExplain1')}</Typography>
            <Typography>2. {t('WhatIsQualityExplain2')}</Typography>
            <Typography>3. {t('WhatIsQualityExplain3')}</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      {/*<Accordion className={mobileClasses.accordion} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>*/}
      {/*  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">*/}
      {/*    <Typography className={mobileClasses.heading}>What is Quality Score?</Typography>*/}
      {/*  </AccordionSummary>*/}
      {/*  <AccordionDetails>*/}
      {/*    <Typography>*/}
      {/*      AI Rating is a scoring system to evaluate potential and liquid stocks. In a nutshell, for each company, we*/}
      {/*      look at many different sets of metrics, divided into three specific criteria: Technical Score, Valuation*/}
      {/*      Score and Quality Score, from which we generate scores for stocks*/}
      {/*    </Typography>*/}
      {/*  </AccordionDetails>*/}
      {/*</Accordion>*/}
    </Paper>
  )
}
