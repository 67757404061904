import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import WhatIsAiRating from '../components/WhatIsAiRating'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t, i18n } = useTranslation()

  return (
    <Layout>
      <SEO title={t('WhatIsAiRating')} />
      <WhatIsAiRating />
    </Layout>
  )
}
