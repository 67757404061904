import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './style'
import { useTranslation } from 'react-i18next'
import ControlledAccordions from './Faq'
import AiRatingPerformanceChart from '../AiInOutAndChart/AiPerformanceChart'
import { isScreenBiggerThan } from '../../utils/parse'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useState } from '@hookstate/core'
import { AiInOutState } from '../AiInOutAndChart/state'

export default function WhatIsAiRating() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const topState = useState(AiInOutState.top)
  const periodState = useState(AiInOutState.chartPeriod)

  const ChartComponent = (styles = classes, heightPercent) => (
    <>
      <Paper className={styles.chartPaper} elevation={0}>
        <Grid container spacing={3}>
          <Grid container item xs={7}>
            <Typography className={styles.nameAndExchangeTypo}>{`${t(
              'Performance'
            )} - Top ${topState.get()}`}</Typography>
          </Grid>
          <Grid container item xs={5} justify={'flex-end'}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={topState.get()}
                onChange={(e) => topState.set(e.target.value as number)}
              >
                <MenuItem value={5}>Top 5</MenuItem>
                <MenuItem value={10}>Top 10</MenuItem>
                <MenuItem value={15}>Top 15</MenuItem>
                <MenuItem value={20}>Top 20</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.selectPeriod}>
              {/*<InputLabel id="demo-simple-select-label">Top 5</InputLabel>*/}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={periodState.get()}
                onChange={(e) => periodState.set(e.target.value as string)}
              >
                <MenuItem value={'All'}>{t('All')}</MenuItem>
                <MenuItem value={'1W'}>1 {t('Week')}</MenuItem>
                <MenuItem value={'1M'}>1 {t('Month')}</MenuItem>
                <MenuItem value={'3M'}>3 {t('Month')}</MenuItem>
                <MenuItem value={'6M'}>6 {t('Month')}</MenuItem>
                <MenuItem value={'1Y'}>1 {t('Year')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <AiRatingPerformanceChart heightPercent={heightPercent} />
        <Typography className={styles.performanceTypo}>{t('PerformanceNote')}</Typography>
      </Paper>
    </>
  )

  return isScreenBiggerThan('md') ? (
    <div>
      <div className={classes.aiRatingDiv}>
        <Typography className={classes.header} align={'center'} variant="h4" gutterBottom>
          {t('WhatIsAiRating')}
        </Typography>
        <div>
          <Typography>{t('WhatIsAiRatingExplain0')}</Typography>
          <Typography>1. {t('WhatIsAiRatingExplain1')}</Typography>
          <Typography>2. {t('WhatIsAiRatingExplain2')}</Typography>
          <Typography>3. {t('WhatIsAiRatingExplain3')}</Typography>
        </div>
      </div>

      {ChartComponent(classes, 40)}
      <ControlledAccordions />
    </div>
  ) : (
    <div>
      <div className={classes.aiRatingDiv}>
        <Typography className={classes.header} align={'center'} variant="h4" gutterBottom>
          {t('WhatIsAiRating')}
        </Typography>
        <div>
          <Typography>{t('WhatIsAiRatingExplain0')}</Typography>
          <Typography>1. {t('WhatIsAiRatingExplain1')}</Typography>
          <Typography>2. {t('WhatIsAiRatingExplain2')}</Typography>
          <Typography>3. {t('WhatIsAiRatingExplain3')}</Typography>
        </div>
      </div>

      <Paper className={classes.chartPaper} elevation={0}>
        <Grid container spacing={1} justify={'space-around'} style={{ marginBottom: 0 }}>
          {/*<Grid container item xs={5}>*/}
          <Typography className={classes.nameAndExchangeTypo}>{`${t('Performance')}`}</Typography>
          {/*</Grid>*/}
          {/*<Grid container item xs={7} justify={'flex-end'}>*/}
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={topState.get()}
              onChange={(e) => topState.set(e.target.value as number)}
            >
              <MenuItem value={5}>Top 5</MenuItem>
              <MenuItem value={10}>Top 10</MenuItem>
              <MenuItem value={15}>Top 15</MenuItem>
              <MenuItem value={20}>Top 20</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.selectPeriod}>
            {/*<InputLabel id="demo-simple-select-label">Top 5</InputLabel>*/}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={periodState.get()}
              onChange={(e) => periodState.set(e.target.value as string)}
            >
              <MenuItem value={'All'}>{t('All')}</MenuItem>
              <MenuItem value={'1W'}>1 {t('Week')}</MenuItem>
              <MenuItem value={'1M'}>1 {t('Month')}</MenuItem>
              <MenuItem value={'3M'}>3 {t('Month')}</MenuItem>
              <MenuItem value={'6M'}>6 {t('Month')}</MenuItem>
              <MenuItem value={'1Y'}>1 {t('Year')}</MenuItem>
            </Select>
          </FormControl>
          {/*</Grid>*/}
        </Grid>

        <AiRatingPerformanceChart heightPercent={80} />
        <Typography className={classes.performanceTypo}>{t('PerformanceNote')}</Typography>
      </Paper>
      <ControlledAccordions />
    </div>
  )
}
