import { createStyles, makeStyles } from '@material-ui/core/styles'
import CONF from '../../config'

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      // marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    aiRatingDiv: {
      marginBottom: theme.spacing(5),
    },
    typo: {},
    header: {
      marginTop: theme.spacing(3),
    },
    chartPaper: {
      width: '100%',
      // marginBottom: theme.spacing(3),
      // marginTop: theme.spacing(2),
    },
    nameAndExchangeTypo: {
      // marginTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: 15,
      color: CONF.css.blue,
      // fontWeight: 700,
      fontSize: '1.3rem',
    },
    selectPeriod: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    performanceTypo: {
      marginLeft: theme.spacing(2),
      color: CONF.css.black,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
)
