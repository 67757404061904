import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      margin: '2rem 0rem 6rem',
      // marginTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingLeft: theme.spacing(25),
      paddingRight: theme.spacing(25),
      backgroundColor: '#eeeeee',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    header: {
      paddingTop: theme.spacing(3),
    },
    accordion: {
      marginTop: theme.spacing(2),
    },
  })
)
